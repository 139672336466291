




































import { defineComponent, computed, ref, watch } from '@vue/composition-api'
import InfoModal from '@/components/InfoModal.vue'
import { UpwardOpportunityTypes } from '@/lib/common/upwardOpportunityTypes'
import store from '@/store'

export default defineComponent({
  name: 'DoNotOrderWarningModal',
  components: { InfoModal },
  setup() {
    const warningCount = computed(() => store.getters.leagueOrders.root.doNotOrderWarningCount)
    const show = ref(false)
    const close = () => (show.value = false)

    const isCamp = computed(() => store.state.leagueAbstraction.programType == UpwardOpportunityTypes.CAMP)

    watch(
      () => warningCount.value,
      () => {
        // only show the first time doNotOrder is checked on an order for a participant
        if (warningCount.value == 1) show.value = true
      }
    )
    return { show, close, isCamp }
  },
})
