


































import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api'
import PeopleFoundGrid from '@/views/Programs/Orders/league/components/PeopleFoundGrid.vue'
import BackButton from '@/views/Programs/Orders/league/components/BackButton.vue'
import SearchInput from '@/elements/SearchInput.vue'
import MissingSizingSelector from '@/views/Programs/Orders/league/components/MissingSizingSelector.vue'
import VeeValidateForm from '@/elements/VeeValidateForm.vue'
import { ParticipantOrderProductInfo } from '@/GeneratedTypes/ListInfo/ParticipantOrderProductInfo'
import { xLeagueOrderDetailParticipantProduct } from '@/GeneratedTypes/xOrder/xStartupOrder'
import {
  LeagueOrderState,
  LOSStates,
  SizeAffectsEnum,
} from '@/lib/support/store/leagueOrder/leagueOrderState'

import { stateTransitions } from '@/views/Programs/Orders/league/lib/LeagueOrderSM'
import { useNewPeopleSizing } from '../lib/useNewPeopleSizing'
import {
  flattenPlayers,
  PeopleWithProgram,
  newPlayerProductFilter,
  newPlayerValidatedFilter,
  calcPlayersWithoutOrder,
} from '@/views/Programs/Orders/league/lib/PeopleWithProgramOps'

export default defineComponent({
  name: 'StepNewParticipants',

  components: { PeopleFoundGrid, BackButton, SearchInput, MissingSizingSelector, VeeValidateForm },
  props: {
    state: { type: Object as PropType<LeagueOrderState>, required: true },
    itemList: { type: Array as PropType<ParticipantOrderProductInfo[]>, required: true, default: () => [] },
  },

  setup(props, ctx) {
    const people = ref<PeopleWithProgram[]>([])
    const filter = (p: xLeagueOrderDetailParticipantProduct) =>
      p.typeSizeID !== 'NONE' || p.typeProductOptionID == 'INCLUDED'

    watch(
      () => props.state,
      () => {
        people.value = flattenPlayers(props.state.orderStatus)
      },
      { deep: true, immediate: true }
    )

    const continueClicked = () =>
      ctx.emit('setCurrentStep', stateTransitions[LOSStates.NEW_PARTICIPANTS].continue(props.state))

    // new people show up on the bottom of the page, this is the logic that handles them
    const uPNS = useNewPeopleSizing({
      people: computed(() => people.value),
      sizeAffects: SizeAffectsEnum.PLAYER,
      calcPeopleWithoutOrder: calcPlayersWithoutOrder,
      // players will show as new if they have required products that do not have an order id.
      filter: newPlayerProductFilter,
      validatedFilter: newPlayerValidatedFilter,
      emit: ctx.emit,
    })

    const canContinue = computed(() => !props.state.loading && !uPNS.peopleNotComplete.value.length)

    return {
      ...uPNS,
      canContinue,
      continueClicked,
      filter,
    }
  },
})
