































































import { computed, defineComponent, PropType, ref, watch, onMounted } from '@vue/composition-api'
import {
  getEmptyPlayerMissingSize,
  PlayerMissingSize,
  PlayerMissingSizeProduct,
} from '@/models/Order/PlayerMissingSize'
import SelectInput from '@/elements/SelectInput.vue'
import { ParticipantOrderProductInfo } from '@/GeneratedTypes/ListInfo/ParticipantOrderProductInfo'
import { cloneDeep } from 'lodash'
import SizeComponent from '@/views/Programs/Orders/league/components/SizeComponent.vue'
import ColumnButton from '@/views/Programs/Orders/league/components/ColumnButton.vue'
import { xLeagueOrderDetailProduct } from '@/GeneratedTypes/xOrder/xStartupOrder'
import { useSizesForItem } from '@/views/Programs/Orders/league/lib/useSizesForItem'
import CheckboxInput from '@/elements/CheckboxInput.vue'
import { isSizeUnselected } from '@/models/Order/OrderProduct'
import store from '@/store'

interface ItemSizeType {
  item: number
  size: string
}

export default defineComponent({
  name: 'SizePerson',
  components: { ColumnButton, SelectInput, SizeComponent, CheckboxInput },
  props: {
    needsAttention: { type: Boolean, required: false, default: false },
    value: { type: Object as PropType<PlayerMissingSize>, required: true },
    showHeadCoachColumn: { type: Boolean, required: false, default: false },
    hasDNO: { type: Boolean, required: false, default: true },

    showGrade: { type: Boolean, required: false, default: true },
    itemList: { type: Array as PropType<ParticipantOrderProductInfo[]>, required: true, default: () => [] },
    filter: {
      type: Function as PropType<(x: PlayerMissingSizeProduct) => boolean>,
      required: false,
      default: () => () => true,
    },
    columnOption: { type: String, required: true, default: '' },
  },
  setup(props, ctx) {
    const item_size = ref<ItemSizeType[]>([])
    const warningCountDNO = computed(() => store.getters.leagueOrders.root.doNotOrderWarningCount)
    const warningCountSNO = computed(() => store.getters.leagueOrders.root.skipThisOrderWarningCount)

    onMounted(async () => {
      await store.dispatch.sizeTypes.fetchAll(false) //get these cached up
    })

    function getItemSize(item: number) {
      const ix = item_size.value.findIndex((x) => x.item == item)
      if (ix >= 0) {
        return item_size.value[ix].size
      }
      return internalValue.value.products.find((x) => x.productID == item)?.typeSizeID ?? 'NONE'
    }

    function changeItemSize(item: number, size: string) {
      const ix = item_size.value.findIndex((x) => x.item == item)
      if (ix >= 0) {
        item_size.value.splice(ix, 1, { item, size })
      } else {
        item_size.value.push({ item, size })
      }
    }

    const internalValue = ref(getEmptyPlayerMissingSize())
    const filteredProducts = computed(() => internalValue.value.products.filter(props.filter))
    const doNotOrderList = ref<number[]>([])
    const skipNextOrderList = ref<number[]>([])

    watch(
      () => props.value,
      () => {
        internalValue.value = cloneDeep(props.value)
        internalValue.value.products.forEach((x) => changeItemSize(x.productID, x.typeSizeID))
        doNotOrderList.value = filteredProducts?.value?.filter((x) => x.doNotOrder)?.map((x) => x.productID)
        skipNextOrderList.value = filteredProducts?.value
          ?.filter((x) => x.skipNextOrder)
          ?.map((x) => x.productID)
      },
      { immediate: true }
    )

    function updateSize(v: xLeagueOrderDetailProduct, size: string) {
      changeItemSize(v.productID, size)

      ctx.emit('sizingUpdate', {
        participantID: internalValue.value.individualID,
        product: { ...v, typeSizeID: size }, //,
      })
    }

    function click(v: xLeagueOrderDetailProduct) {
      const size = item_size.value?.find((x) => x.item == v.productID)?.size ?? 'NONE'

      ctx.emit('sizingClick', {
        participantID: internalValue.value.individualID,
        product: { ...v, typeSizeID: size },
      })
    }

    function showWarningDNO(checked: boolean) {
      if (warningCountDNO.value < 1 && checked) {
        store.commit.leagueOrders.incrementDoNotOrderWarningCount()
      }
    }

    function showWarningSNO(checked: boolean) {
      if (warningCountSNO.value < 1 && checked) {
        store.commit.leagueOrders.incrementSkipThisOrderWarningCount()
      }
    }

    function updateDNO(v: xLeagueOrderDetailProduct, isEnabled: boolean) {
      showWarningDNO(isEnabled)
      const index = doNotOrderList.value.indexOf(v.productID)

      if (index >= 0 && !isEnabled) {
        doNotOrderList.value.splice(index, 1)
      } else if (isEnabled) {
        doNotOrderList.value.push(v.productID)
      }

      const p = filteredProducts.value.find((x) => x.productID == v.productID)

      ctx.emit('sizingUpdate', {
        participantID: internalValue.value.individualID,
        product: { ...p, doNotOrder: isEnabled },
      })
    }

    function updateSNO(v: xLeagueOrderDetailProduct, isEnabled: boolean) {
      showWarningSNO(isEnabled)
      const index = skipNextOrderList.value.indexOf(v.productID)

      if (index >= 0 && !isEnabled) {
        skipNextOrderList.value.splice(index, 1)
      } else if (isEnabled) {
        skipNextOrderList.value.push(v.productID)
      }

      const p = filteredProducts.value.find((x) => x.productID == v.productID)

      ctx.emit('sizingUpdate', {
        participantID: internalValue.value.individualID,
        product: { ...p, skipNextOrder: isEnabled },
      })
    }

    return {
      invalid: (x: xLeagueOrderDetailProduct) =>
        isSizeUnselected(getItemSize(x.productID)) && !doNotOrderList.value.find((y) => x.productID == y),
      internalValue,
      updateSize,
      item_size,
      click,
      filteredProducts,
      updateDNO,
      updateSNO,
      doNotOrderList,
      skipNextOrderList,
      ...useSizesForItem({ itemList: computed(() => props.itemList) }),
    }
  },
})
