





























import { defineComponent, PropType } from '@vue/composition-api'

import SelectInput from '@/elements/SelectInput.vue'
import { PeopleWithProgram } from '@/views/Programs/Orders/league/lib/PeopleWithProgramOps'

export default defineComponent({
  name: 'SizeComponent',
  components: { SelectInput },
  props: {
    needsAttention: { type: Boolean, required: false, default: false },

    value: { type: Object as PropType<PeopleWithProgram>, required: true },
    showHeadCoachColumn: { type: Boolean, required: false, default: null },

    showGrade: { type: Boolean, required: false, default: true },
  },
})
