import { SavedProductOfferingConfigDetail } from '@/GeneratedTypes/SavedProductOfferingConfigDetail'
import { ParticipantOrderProductInfo } from '@/GeneratedTypes/ListInfo/ParticipantOrderProductInfo'
import { getEmptyKitItem, KitItem } from '@/views/Programs/Orders/league/types/KitItem'
import { ProductCategoryEnum as c } from '@/lib/common/LeagueProductCategories'

// Build out participant and coach product kits
const buildKits = (
  details: SavedProductOfferingConfigDetail[],
  catalogue: ParticipantOrderProductInfo[],
  programs: string[]
) => {
  const allItems = mergeProductData(details, catalogue)
  const participantKits = kitByProgramAndRole(participantProductCategories, programs, allItems)
  const coachKits = kitByProgramAndRole(coachProductCategories, programs, allItems)
  return { participantKits, coachKits }
}

// Merge data from the product catalog and currentLeague.productConfig.details[]
// to form the needed KitItem information
const mergeProductData = (
  details: SavedProductOfferingConfigDetail[],
  catalogue: ParticipantOrderProductInfo[]
) => {
  return details?.map((d) => {
    const prod = catalogue.find((p) => p.id == d.productID)
    return {
      id: d.productID,
      program: prod?.typeProgramID,
      purchaseType: d.gradesByGender && d.gradesByGender.length ? 'GRADEGENDER' : d.typeProductOptionID,
      name: prod?.cmsProductName ?? prod?.upwardProductID, //upwardProductID for testing before CMS data is in.
      productCategoryId: d.categoryID,
      gradeGender: d.gradesByGender && d.gradesByGender.length ? d.gradesByGender : [],
    } as KitItem
  })
}

// Group the kit by program (e.g. cheer, sport) and role (e.g. participant, coach)
const kitByProgramAndRole = (categoried: number[], programs: string[], allItems: KitItem[]) => {
  return programs.map((p) => {
    const noApparel = [{ ...getEmptyKitItem(), ...{ name: 'No apparel selected', program: p, id: 9999 } }]
    const kit = allItems?.filter(
      (product) => product.program == p && categoried.some((c) => c == product.productCategoryId)
    )
    return {
      program: p,
      kit: !kit.length ? noApparel : kit,
    }
  })
}

const participantProductCategories = [
  c.AUTOINCLUDE,
  c.OPTIONAL_APPAREL,
  c.PLAYER_ITEMS,
  c.PLAYER_RECOGNITION,
  c.AWARDS,
  c.PLAYER_BAG,
]
const coachProductCategories = [c.COACH_APPAREL]

export { buildKits }
