





































import { computed, defineComponent, PropType } from '@vue/composition-api'
import { LeagueOrderState, LOSStates } from '@/lib/support/store/leagueOrder/leagueOrderState'
import { ParticipantOrderProductInfo } from '@/GeneratedTypes/ListInfo/ParticipantOrderProductInfo'
import { isCheerProgram } from '@/lib/support/models/LeagueProgram/data'
import { componentState, stateTransitions } from '@/views/Programs/Orders/league/lib/LeagueOrderSM'
import { xLeagueOrderDetail } from '@/GeneratedTypes/xOrder/xStartupOrder'
import BackButton from '@/views/Programs/Orders/league/components/BackButton.vue'
import { LeagueAbstraction } from '@/models/LeagueAbstraction/LeagueAbstraction'
import { LeagueTypeEnum } from '@/models/LeagueAbstraction/enums/LeagueTypeEnum'

export default defineComponent({
  components: { BackButton },
  props: {
    league: { type: Object as PropType<LeagueAbstraction>, required: true },
    state: { type: Object as PropType<LeagueOrderState>, required: true },
    itemList: { type: Array as PropType<ParticipantOrderProductInfo[]>, required: true, default: () => [] },
  },
  setup(props, ctx) {
    const totalParticipants = computed(() =>
      getTotalPeople(props.state.orderStatus.find((x) => !isCheerProgram(x.typeProgramID)) ?? null)
    )
    const totalCheerParticipants = computed(() =>
      getTotalPeople(props.state.orderStatus.find((x) => isCheerProgram(x.typeProgramID)) ?? null)
    )

    function getTotalPeople(x: xLeagueOrderDetail | null) {
      let rv = 0

      x?.divisions.forEach((div) => {
        div.teams.forEach((team) => {
          rv += team.players.length
        })
      })
      return rv
    }

    const labelForProceed = computed(() => {
      return (
        componentState.find(
          (x) =>
            x.step ==
            stateTransitions[LOSStates.GET_PARTICIPANT_DETAIL_PREFERENCE].proceed(props.state).currentStep
        )?.label ?? 'Unknown Step'
      )
    })

    function proceedClicked() {
      ctx.emit(
        'setCurrentStep',
        stateTransitions[LOSStates.GET_PARTICIPANT_DETAIL_PREFERENCE].proceed(props.state)
      )
    }

    function reviewClicked() {
      ctx.emit(
        'setCurrentStep',
        stateTransitions[LOSStates.GET_PARTICIPANT_DETAIL_PREFERENCE].review(props.state)
      )
    }
    return {
      LeagueTypeEnum,
      totalParticipants,
      totalCheerParticipants,
      reviewClicked,
      proceedClicked,
      labelForProceed,
    }
  },
})
