






































































import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api'
import { stateTransitions } from '@/views/Programs/Orders/league/lib/LeagueOrderSM'
import { LeagueOrderState, LOSStates } from '@/lib/support/store/leagueOrder/leagueOrderState'
import { ParticipantOrderProductInfo } from '@/GeneratedTypes/ListInfo/ParticipantOrderProductInfo'
import BackButton from '@/views/Programs/Orders/league/components/BackButton.vue'
import { useNoProductSelector } from '@/views/Programs/Orders/compositions/useProductSelector'
import OrderListSection from '@/components/OrderHistory/OrderListSection.vue'

import ShippingAddressSection from '@/components/ShippingAddressSection.vue'
import InputLabel from '@/elements/InputLabel.vue'
import { getEmptyxShipToBase } from '@/lib/support/models/GeneratedTypes/xOrders/xShipToBase'
import currency from '@/filters/currency'
import Loading from '@/elements/Loading.vue'
import { ValidateLeagueOrderShippingPayload } from '@/clients/ordersClient'
import OrderPriceBreakdown from '@/views/Programs/Orders/league/components/OrderPriceBreakdown.vue'
import SectionHeader from '@/components/SectionHeader.vue'

export default defineComponent({
  methods: { currency },
  components: {
    OrderPriceBreakdown,
    Loading,
    BackButton,
    OrderListSection,
    ShippingAddressSection,
    SectionHeader,
    InputLabel,
  },
  props: {
    state: { type: Object as PropType<LeagueOrderState>, required: true },
    itemList: { type: Array as PropType<ParticipantOrderProductInfo[]>, required: true, default: () => [] },
  },
  setup(props, ctx) {
    const currentOrder = computed(() => props.state.template)
    const currentOrderNotes = computed(() => props.state.template.notes)
    const currentPurchaseOrder = computed(() => props.state.template.purchaseOrderNumber)
    const currentOrderProducts = computed(() => props.state.template.products)
    const currentOrderShipTo = computed(
      () => props.state.template.shippingInfo?.shipTo ?? getEmptyxShipToBase()
    )
    const showOrderDetails = ref(false)
    const eventPayload = computed(
      () =>
        ({
          accountNumber: props.state.template.accountNumber,
          notes: currentOrderNotes.value,
          processingInstructions: [],
          purchaseOrderNumber: currentOrder.value.purchaseOrderNumber,
          shippingInfo: { ...props.state.template.shippingInfo, shipTo: { ...currentOrderShipTo.value } },
          upwardLeagueID: props.state.template.upwardLeagueID,
        } as ValidateLeagueOrderShippingPayload)
    )

    watch(
      () => props.state.validated,
      async () => {
        if (props.state.validated) {
          await ctx.emit('setCurrentStep', stateTransitions[LOSStates.REVIEW_ORDER].continue(props.state))
        }
      }
    )

    return {
      currentOrderShipTo,
      currentPurchaseOrder,
      currentOrder,
      currentOrderNotes,
      currentOrderProducts,
      showOrderDetails,
      selectorMethod: useNoProductSelector({
        currentOrderProductsRef: computed(() => props.state.template.products),
      }),
      continueClicked: async () => {
        await ctx.emit('confirmOrder', eventPayload.value)
      },
    }
  },
})
