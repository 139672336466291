







































import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api'
import { cloneDeep } from 'lodash'
import { PlayerMissingSize, PlayerMissingSizeProduct } from '@/models/Order/PlayerMissingSize'
import { ParticipantOrderProductInfo } from '@/GeneratedTypes/ListInfo/ParticipantOrderProductInfo'
import SizePerson from '@/views/Programs/Orders/league/components/SizePerson.vue'
import VeeValidateForm from '@/elements/VeeValidateForm.vue'
import SizeCoach from '@/views/Programs/Orders/league/components/SizeCoach.vue'

export default defineComponent({
  name: 'PeopleFoundGrid',
  components: {
    VeeValidateForm,
    SizePerson,
    SizeCoach,
  },
  props: {
    showGrade: { type: Boolean, required: false, default: true },
    showHeadCoachColumn: { type: Array as PropType<number[]>, required: false, default: null },
    value: { type: Array as PropType<PlayerMissingSize[]>, required: true, default: () => [] },
    itemList: { type: Array as PropType<ParticipantOrderProductInfo[]>, required: true, default: () => [] },
    filter: {
      type: Function as PropType<(x: PlayerMissingSizeProduct) => boolean>,
      required: false,
      default: () => () => true,
    },
  },
  setup(props, ctx) {
    const internalValue = ref<PlayerMissingSize[]>([])
    watch(
      () => props.value,
      () => (internalValue.value = cloneDeep(props.value)),
      { immediate: true }
    )
    function sizingUpdated(payload: unknown) {
      ctx.emit('sizingUpdate', payload)
    }
    //I asked thomas about this (e.g. visual consistency vs. code organization) he prefers the gray bars flip on odd products.
    // the problem is that vue requires a root element so we can't rely on css to style every other line grey
    // therefore we figure out if grey is on top or white is on top based on iterating through the product counts
    const prodCountMap = computed(() => {
      if (props.showHeadCoachColumn) {
        return internalValue.value.map(() => 2)
      } else return internalValue.value.map((x) => (x.products.filter(props.filter).length + 1) % 2)
    })
    //** calculates the need to reverse even and odd based on products.
    const prodFlippy = computed(() =>
      prodCountMap.value.map((x, i, a) => a.reduce((aa, va, ai) => (ai < i ? aa + va : aa), 1) % 2)
    )
    return {
      internalValue,
      sizingUpdated,

      prodFlippy,
    }
  },
})
