export interface PlayerMissingSize {
  leagueID: number
  accountNumber: string
  firstName: string
  lastName: string
  gender: string
  individualID: string
  typeGradeID: string
  products: PlayerMissingSizeProduct[]
}

export interface PlayerMissingSizeProduct {
  productID: number
  parentProductID: number
  categoryID: number
  typeProductOptionID: string //"INCLUDED"
  typeColorID: string
  typeSizeID: string
  quantity: 1
  upwardOrderID: string
  doNotOrder: boolean
  skipNextOrder: boolean
  jerseyNumber: string
  isRequired: boolean
  cmsProductName: string
  cmsProductDescription: string
  cmsProductImageUrls: string[]
}

export function getEmptyPlayerMissingSize() {
  return {
    accountNumber: '',
    firstName: '',
    lastName: '',
    gender: 'M',
    individualID: '0',
    leagueID: 0,
    products: [],
    typeGradeID: '',
  } as PlayerMissingSize
}
