<!-- column button is used in sizing for coach and participant, allows for styling later -->
<template>
  <button class="button btn btn-primary column-button" @click="$emit('click')">
    <span v-if="columnOption == 'add'"><i class="fa fa-plus-circle" aria-hidden="true"></i> add</span>
    <span v-if="columnOption == 'remove'"><i class="fa fa-times-circle" aria-hidden="true"></i> remove</span>
  </button>
</template>

<script type="ts">
import {defineComponent} from "@vue/composition-api";

export default defineComponent( {
  name: "ColumnButton",
  props: {

    columnOption: { type: String, required: true, default: '' },
}})
</script>
<style lang="scss">
.column-button {
  margin-left: 0.25em;
  margin-right: 0.25em;
  margin-bottom: 0.25em;
  height: 2.5em;
}
</style>
