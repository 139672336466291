






















import { ValdiatorInterface } from '@/models/Validation/ValidatorInterface'

import { computed, defineComponent, onMounted, PropType, ref, watch } from '@vue/composition-api'
import {
  LeagueOrderState,
  LOSStates,
  SizeAffectsEnum,
  SizeUpdatedPayload,
} from '@/lib/support/store/leagueOrder/leagueOrderState'
import { ParticipantOrderProductInfo } from '@/GeneratedTypes/ListInfo/ParticipantOrderProductInfo'
import MissingSizingSelector from '@/views/Programs/Orders/league/components/MissingSizingSelector.vue'
import {
  addProgramToSizing,
  getProductID,
  stateTransitions,
} from '@/views/Programs/Orders/league/lib/LeagueOrderSM'
import VeeValidateForm from '@/elements/VeeValidateForm.vue'
import { cloneDeep } from 'lodash'
import { useProductTracking } from '@/views/Programs/Orders/league/lib/useProductTracking'
import { PlayerMissingSize } from '@/models/Order/PlayerMissingSize'
import Vue from 'vue'
import {
  passRequiredProductsWithSizeUnsetProductFilter,
  validatePlayersMissingSizes,
} from '@/views/Programs/Orders/league/lib/PeopleWithProgramOps'

export default defineComponent({
  name: 'StepParticipantMissingSizes',
  components: { MissingSizingSelector, VeeValidateForm },
  props: {
    state: { type: Object as PropType<LeagueOrderState>, required: true },
    itemList: { type: Array as PropType<ParticipantOrderProductInfo[]>, required: true, default: () => [] },
  },

  setup(props, ctx) {
    const validate = ref<null | ValdiatorInterface>(null)
    const internalSizes = ref<PlayerMissingSize[][]>([])
    const allSizing = ref<PlayerMissingSize[]>([])

    //combines missing sizes into one big hunk (from one program or both).
    const missingSizes = computed(() => {
      return cloneDeep(
        internalSizes.value.length == 1
          ? addProgramToSizing(internalSizes.value[0], props.state.programs[0]).map((x) => ({
              ...x,
              description: x.typeGradeID,
            }))
          : [
              ...addProgramToSizing(internalSizes.value[0], props.state.programs[0]).map((x) => ({
                ...x,
                description: x.typeGradeID,
              })),
              ...addProgramToSizing(internalSizes.value[1], props.state.programs[1]).map((x) => ({
                ...x,
                description: x.typeGradeID,
              })),
            ]
      )
    })

    //props represents the backend state, where internalState is going to be the state at component load.
    const remainingPeople = computed(() =>
      allSizing.value.filter(validatePlayersMissingSizes).map((x) => x.individualID)
    )

    watch(
      () => props.state,
      () =>
        (allSizing.value = cloneDeep(
          props.state.sizing.length == 1
            ? props.state.sizing[0]
            : [...props.state.sizing[0], ...props.state.sizing[1]]
        )),
      { immediate: true, deep: true }
    )

    //should exclude erroneously passed products that have sizes or products that are not required.
    const pms = useProductTracking(missingSizes, passRequiredProductsWithSizeUnsetProductFilter)

    //on a product update, fire a BE change to set the size on the product.
    function sizingUpdated(payload: SizeUpdatedPayload) {
      const program = missingSizes.value.find(
        (x) =>
          parseInt(x.individualID) == payload.participantID &&
          x.products.find((y) => y.productID == getProductID(payload.product))
      )?.program
      //sometimes quantity is zero force it here.
      ctx.emit(
        'sizingUpdate',
        cloneDeep({
          ...payload,
          product: { ...payload.product, quantity: 1 },
          program,
          whoIsUpdated: SizeAffectsEnum.PLAYER,
        })
      )
      pms.addModifiedSize(payload)
    }

    //if we load and meet a condition of all clear, go on to the next step
    onMounted(() => {
      // allows changing the starting values, but state won't be reflected until complete with the step
      // since sizes will disappear if they are set to a valid value.
      internalSizes.value = cloneDeep(props.state.sizing)
      Vue.nextTick(() => {
        if (pms.allProducts.value.length == 0) {
          ctx.emit(
            'setCurrentStep',
            stateTransitions[LOSStates.PARTICIPANT_MISSING_SIZES].continue(props.state)
          )
        }
      })
    })

    //continue event handler.
    const continueClicked = () =>
      ctx.emit('setCurrentStep', stateTransitions[LOSStates.PARTICIPANT_MISSING_SIZES].continue(props.state))
    const canContinue = computed(() => !props.state.loading && !remainingPeople.value.length)

    return {
      allSizing,
      missingSizes,
      remainingPeople,
      canContinue,
      ...pms,
      sizingUpdated,
      continueClicked,
      productDisplayFilter: passRequiredProductsWithSizeUnsetProductFilter,
      validate,
    }
  },
})
