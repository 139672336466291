


































import { computed, defineComponent } from '@vue/composition-api'
import currency from '@/filters/currency'
import store from '@/store'
import { PendingOrderAdditionalProductInfo } from '@/GeneratedTypes/ListInfo/PendingOrderAdditionalProductInfo'
import { OrderProduct } from '@/models/Order/OrderProduct'
import { OrderProductSize } from '@/models/Order/OrderProductSize'
import { cloneDeep } from 'lodash'
import { filterNA } from '@/models/Order/OrderProduct'

interface AddOnCartProduct extends PendingOrderAdditionalProductInfo {
  cost: number
  name: string
  color: string
  size: string
  pendingProduct: PendingOrderAdditionalProductInfo
}

export default defineComponent({
  name: 'AddOnCart',
  components: {},
  props: {
    readOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
    title: { type: String, default: '', required: false },
  },
  setup(props, ctx) {
    const pendingItems = computed(() =>
      cloneDeep(store.getters.leagueOrders.root.pendingAddOnItems).sort((a, b) => (a.id > b.id ? 1 : -1))
    )

    const products = computed(() => {
      // merge pending items with information needed for display (like cmsProductName, cost, etc)
      return (
        pendingItems.value
          .filter((i) => i.individualID == 0)
          .map((p) => {
            const { base, colorSize } = catalogProduct(p.productID, p.typeSizeID, p.typeColorID)
            return addOnCartProduct(p, base, colorSize)
          }) ?? []
      )
    })

    function rowKey(product: AddOnCartProduct) {
      const p = product.pendingProduct
      return `${p.id}-${p.typeColorID}-${p.typeSizeID}-${p.jerseyNumber}`
    }

    function addOnCartProduct(
      pending: PendingOrderAdditionalProductInfo,
      base: OrderProduct | undefined,
      colorSize: OrderProductSize | undefined
    ) {
      return {
        pendingProduct: pending,
        cost: base && colorSize ? lineTotal(base.cost, colorSize.costOffset, pending.quantity) : 0,
        name: base?.cmsProductName,
        color: filterNA(colorSize?.colorDescription ?? ''),
        size: filterNA(colorSize?.sizeDescription ?? '')
          ? `(${filterNA(colorSize?.sizeDescription ?? '')})`
          : '',
      } as AddOnCartProduct
    }

    const productCatalog = computed(() => cloneDeep(store.getters.leagueOrders.root.template.products))
    function catalogProduct(id: number, size: string | null, color: string | null) {
      const base = productCatalog.value.find((pp) => pp.id == id)
      const colorSize = base?.productColorSizes?.find((s) => s.typeSizeID == size && s.typeColorID == color)
      return { base, colorSize }
    }

    function lineTotal(cost: number, offset: number | undefined, quantity: number) {
      return (cost + (offset ?? 0)) * quantity
    }

    const total = computed(() => products.value.reduce((t, x) => t + x.cost, 0))

    function remove(p: AddOnCartProduct) {
      if (!props.readOnly) {
        ctx.emit('remove', p.pendingProduct)
      }
    }

    return { products, currency, remove, total, rowKey }
  },
})
