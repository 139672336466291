import { PlayerMissingSize, PlayerMissingSizeProduct } from '@/models/Order/PlayerMissingSize'
import { computed, ComputedRef, ref } from '@vue/composition-api'
import { ItemSizedHistoryType } from '@/models/Order/ItemSizedHistoryType'
import { getProductID } from '@/views/Programs/Orders/league/lib/LeagueOrderSM'

import { SizeUpdatedPayload } from '@/lib/support/store/leagueOrder/leagueOrderState'

export function useProductTracking(
  missingSizes: ComputedRef<PlayerMissingSize[]>,
  filter: (x: PlayerMissingSizeProduct) => boolean
) {
  const allProducts = computed(() => {
    const id: ItemSizedHistoryType[] = []
    missingSizes.value.forEach((x) => {
      x.products.filter(filter).forEach((y) => {
        id.push({ id: parseInt(x.individualID), product: y.productID })
      })
    })
    return id
  })

  const modifiedSizes = ref<ItemSizedHistoryType[]>([])

  /**
   * Keeps a list of sizes that have been modified.
   * @param payload
   */
  function addModifiedSize(payload: SizeUpdatedPayload) {
    if (
      !modifiedSizes.value.find(
        (x) => x.product == getProductID(payload.product) && x.id == payload.participantID
      )
    ) {
      modifiedSizes.value.push({ id: payload.participantID, product: getProductID(payload.product) })
    }
  }

  return { addModifiedSize, allProducts }
}
