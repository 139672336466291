import { SavedProductOfferingConfigDetailGenderGrade } from '@/GeneratedTypes/SavedProductOfferingConfigDetailGenderGrade'

export interface KitItem {
  name: string
  id: number
  purchaseType: string
  program: string
  productCategoryId: number
  gradeGender: SavedProductOfferingConfigDetailGenderGrade[]
}

export function getEmptyKitItem() {
  return {
    name: '',
    id: 0,
    purchaseType: '',
    program: '',
    productCategoryId: 0,
    gradeGender: [],
  }
}
