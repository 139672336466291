import { computed, ComputedRef } from '@vue/composition-api'
import { SizeAffectsEnum, SizeUpdatedPayload } from '@/lib/support/store/leagueOrder/leagueOrderState'
import { PeopleWithProgram } from '@/views/Programs/Orders/league/lib/PeopleWithProgramOps'
import lodash from 'lodash'

export function useNewPeopleSizing({
  people,
  sizeAffects,
  calcPeopleWithoutOrder,
  filter,
  validatedFilter,
  emit,
}: {
  people: ComputedRef<PeopleWithProgram[]>
  sizeAffects: SizeAffectsEnum
  calcPeopleWithoutOrder: (
    p: PeopleWithProgram[],
    filter: (x: PeopleWithProgram) => boolean
  ) => PeopleWithProgram[]
  filter: (x: PeopleWithProgram) => boolean
  validatedFilter: (x: PeopleWithProgram) => boolean
  emit: (event: string, ...args: any[]) => void
}) {
  const peopleWithoutOrder = computed(() => calcPeopleWithoutOrder(people.value, filter))
  const peopleNotComplete = computed(() =>
    lodash.difference(
      peopleWithoutOrder.value.map((x) => x.individualID),
      peopleWithoutOrder.value.filter(validatedFilter).map((x) => x.individualID)
    )
  )

  function sizingUpdated(payload: SizeUpdatedPayload) {
    const program =
      peopleWithoutOrder.value.find((x) => x.individualID == payload.participantID)?.program ?? ''
    emit('sizingUpdate', {
      ...payload,
      program,

      whoIsUpdated: sizeAffects,
    } as SizeUpdatedPayload)
  }

  return { peopleWithoutOrder, peopleNotComplete, sizingUpdated }
}
