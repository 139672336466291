
































import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api'
import PeopleFoundGrid from '@/views/Programs/Orders/league/components/PeopleFoundGrid.vue'
import BackButton from '@/views/Programs/Orders/league/components/BackButton.vue'
import SearchInput from '@/elements/SearchInput.vue'
import MissingSizingSelector from '@/views/Programs/Orders/league/components/MissingSizingSelector.vue'
import VeeValidateForm from '@/elements/VeeValidateForm.vue'
import { ParticipantOrderProductInfo } from '@/GeneratedTypes/ListInfo/ParticipantOrderProductInfo'
import {
  LeagueOrderState,
  LOSStates,
  SizeAffectsEnum,
} from '@/lib/support/store/leagueOrder/leagueOrderState'
import { stateTransitions } from '@/views/Programs/Orders/league/lib/LeagueOrderSM'
import { useNewPeopleSizing } from '../lib/useNewPeopleSizing'
import {
  PeopleWithProgram,
  flattenCoaches,
  newCoachProductFilter,
  coachValidatedProductFilter,
  calcCoacheshWithoutOrder,
} from '@/views/Programs/Orders/league/lib/PeopleWithProgramOps'

export default defineComponent({
  name: 'StepNewCoaches',

  components: { PeopleFoundGrid, BackButton, SearchInput, MissingSizingSelector, VeeValidateForm },
  props: {
    state: { type: Object as PropType<LeagueOrderState>, required: true },
    itemList: { type: Array as PropType<ParticipantOrderProductInfo[]>, required: true, default: () => [] },
  },

  setup(props, ctx) {
    const people = ref<PeopleWithProgram[]>([])

    function compareCoaches(a: PeopleWithProgram, b: PeopleWithProgram) {
      if (a.isHeadCoach && !b.isHeadCoach) {
        return -1
      }
      if (!a.isHeadCoach && b.isHeadCoach) {
        return 1
      }

      return a.name.localeCompare(b.name)
    }

    watch(
      () => props.state,
      () => {
        people.value = flattenCoaches(props.state.orderStatus).sort(compareCoaches)
      },
      { deep: true, immediate: true }
    )

    const continueClicked = () =>
      ctx.emit('setCurrentStep', stateTransitions[LOSStates.NEW_COACHES].continue(props.state))

    const uNPS = useNewPeopleSizing({
      people: computed(() => people.value),
      sizeAffects: SizeAffectsEnum.COACH,
      calcPeopleWithoutOrder: calcCoacheshWithoutOrder,
      //if a coach doesn't have an order ID in the product stack, then probably new/unordered for.
      filter: newCoachProductFilter,
      validatedFilter: coachValidatedProductFilter,
      emit: ctx.emit,
    })
    const canContinue = computed(() => !props.state.loading && !uNPS.peopleNotComplete.value.length)

    return {
      rawpeople: people,
      canContinue,
      ...uNPS,
      continueClicked,
    }
  },
})
