






import { useBack } from '../lib/useBack'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  setup: useBack,
  props: { disabled: { type: Boolean, required: false, default: false } },
})
