//could be refactored out, currently this logic is participant-specific
import { ParticipantOrderProductInfo } from '@/GeneratedTypes/ListInfo/ParticipantOrderProductInfo'
import { getEmptyParticipantOrderProductInfo } from '@/lib/support/models/ListInfo/ParticipantOrderProductInfo/data'
import { productList2ProductOption } from '@/lib/support/models/ListInfo/ProductColorSizeInfo/data'
import { ComputedRef } from '@vue/composition-api'

export function useSizesForItem({ itemList }: { itemList: ComputedRef<ParticipantOrderProductInfo[]> }) {
  const sizesForItem = (itemnumber: number) =>
    productList2ProductOption(findProduct(itemnumber).productColorSizes ?? [])

  /**
   * Quick way to get a product by ID from the product list
   */
  function findProduct(x: number) {
    return itemList.value.find((y) => y.id == x) ?? getEmptyParticipantOrderProductInfo()
  }

  return { sizesForItem, findProduct }
}
