
























































































































import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api'
import {
  getEmptyPlayerMissingSize,
  PlayerMissingSize,
  PlayerMissingSizeProduct,
} from '@/models/Order/PlayerMissingSize'
import SelectInput from '@/elements/SelectInput.vue'
import { ParticipantOrderProductInfo } from '@/GeneratedTypes/ListInfo/ParticipantOrderProductInfo'
import { cloneDeep, groupBy } from 'lodash'
import SizeComponent from '@/views/Programs/Orders/league/components/SizeComponent.vue'
import CheckboxInput from '@/elements/CheckboxInput.vue'
import ColumnButton from '@/views/Programs/Orders/league/components/ColumnButton.vue'
import { useSizesForItem } from '@/views/Programs/Orders/league/lib/useSizesForItem'
import { isSizeUnselected } from '@/models/Order/OrderProduct'

interface GroupedProducts {
  selectedProductID: number
  selectedSizeID: string
  doNotOrder: boolean
  skipNextOrder: boolean
  products: PlayerMissingSizeProduct[]
}

export default defineComponent({
  name: 'SizeCoach',
  components: { ColumnButton, CheckboxInput, SelectInput, SizeComponent },
  props: {
    needsAttention: { type: Boolean, required: false, default: false },

    value: { type: Object as PropType<PlayerMissingSize>, required: true },
    showHeadCoachColumn: { type: Boolean, required: false, default: null },

    showGrade: { type: Boolean, required: false, default: true },
    itemList: { type: Array as PropType<ParticipantOrderProductInfo[]>, required: true, default: () => [] },
    filter: {
      type: Function as PropType<(x: PlayerMissingSizeProduct) => boolean>,
      required: false,
      default: () => () => true,
    },
    columnOption: { type: String, required: false, default: '' },
  },
  setup(props, ctx) {
    const internalValue = ref(getEmptyPlayerMissingSize())
    const filteredProducts = computed(() => internalValue.value.products.filter(props.filter))
    const groupedProducts = ref<GroupedProducts[]>([])

    watch(
      () => props.value,
      () => {
        internalValue.value = cloneDeep(props.value)

        groupedProducts.value = []
        //add products that are standalone
        filteredProducts.value
          .filter((x) => x.parentProductID == 0)
          .forEach((prod) => {
            groupedProducts.value.push({
              selectedProductID: prod.productID,
              selectedSizeID: prod.typeSizeID ?? 'NONE',
              doNotOrder: prod.doNotOrder,
              skipNextOrder: prod.skipNextOrder,
              products: [prod],
            })
          })
        //find all products with matching parents
        const productsWithParents = groupBy(
          filteredProducts.value.filter((x) => x.parentProductID > 0),
          'parentProductID'
        )

        Object.keys(productsWithParents).forEach((key) => {
          const selectedProd =
            productsWithParents[key].find((x) => x.typeSizeID != 'NONE') ?? productsWithParents[key][0]
          groupedProducts.value.push({
            selectedProductID: selectedProd.productID,
            selectedSizeID: selectedProd.typeSizeID ?? 'NONE',
            doNotOrder: selectedProd.doNotOrder,
            skipNextOrder: selectedProd.skipNextOrder,
            products: productsWithParents[key],
          })
        })
      },

      { immediate: true }
    )

    function updateSize(v: GroupedProducts) {
      if (v.selectedSizeID != 'NONE') {
        //dont send the update if no size is selected (ie: if missing size and male/female selected first)
        v.products.forEach((p) => {
          if (p.productID == v.selectedProductID) {
            ctx.emit('sizingUpdate', {
              participantID: internalValue.value.individualID,
              product: { ...p, typeSizeID: v.selectedSizeID, replaced: true },
            })
          } else {
            //send an empty or no-size for everything but the selected product.
            ctx.emit('sizingUpdate', {
              participantID: internalValue.value.individualID,
              product: { ...p, typeSizeID: 'NONE', replaced: true },
            })
          }
        })
      }
    }

    /*
    function updateSize(v: xLeagueOrderDetailProduct, size: string) {
      currentSize.value = size
      filteredProducts.value.forEach((p) => {
        if (p.productID == currentProductID.value) {
          ctx.emit('sizingUpdate', {
            participantID: internalValue.value.individualID,
            product: { ...v, typeSizeID: currentSize.value, replaced: true },
          })
        } else {
          //send an empty or no-size for everything but the selected product.
          ctx.emit('sizingUpdate', {
            participantID: internalValue.value.individualID,
            product: p,
            typeSizeID: 'NONE',
            replaced: true,
          })
        }
      })
    }
    */

    function updateDNO(v: GroupedProducts) {
      v.products.forEach((p) => {
        ctx.emit('sizingUpdate', {
          participantID: internalValue.value.individualID,
          product: { ...p, doNotOrder: v.doNotOrder },
        })
      })
    }

    function updateSNO(v: GroupedProducts) {
      v.products.forEach((p) => {
        ctx.emit('sizingUpdate', {
          participantID: internalValue.value.individualID,
          product: { ...p, skipNextOrder: v.skipNextOrder },
        })
      })
    }

    /*
    function updateDNO(v: xLeagueOrderDetailProduct, isEnabled: boolean) {
      const index = doNotOrderList.value.indexOf(v.productID)

      if (index >= 0 && !isEnabled) {
        doNotOrderList.value.splice(index, 1)
      } else if (isEnabled) {
        doNotOrderList.value.push(v.productID)
      }

      filteredProducts.value.forEach((p) => {
        ctx.emit('sizingUpdate', {
          participantID: internalValue.value.individualID,
          product: { ...p, doNotOrder: isEnabled },
        })
      })
    }
    */

    return {
      internalValue,
      updateSize,
      updateDNO,
      updateSNO,
      groupedProducts,
      ...useSizesForItem({ itemList: computed(() => props.itemList) }),
      invalid: (x: GroupedProducts) =>
        isSizeUnselected(x.selectedSizeID) && !x.doNotOrder && !x.skipNextOrder,
    }
  },
})
