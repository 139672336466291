import { LeagueOrderState, LOSStates } from '@/lib/support/store/leagueOrder/leagueOrderState'
import { cloneDeep } from 'lodash'
import { PlayerMissingSize } from '@/models/Order/PlayerMissingSize'
import { ParticipantOrderProductInfo } from '@/GeneratedTypes/ListInfo/ParticipantOrderProductInfo'
import { xLeagueOrderDetail, xLeagueOrderDetailProduct } from '@/GeneratedTypes/xOrder/xStartupOrder'
import { RuntimeException } from '@/lib/common/exceptions/RuntimeException'
import {
  calcPlayersWithoutOrder,
  calcCoacheshWithoutOrder,
  flattenCoaches,
  flattenPlayers,
  newCoachProductFilter,
  newPlayerProductFilter,
} from '@/views/Programs/Orders/league/lib/PeopleWithProgramOps'

class LeaugeOrderSMRuntimeException extends RuntimeException {
  name = 'League Order SM Error'
}

function nextStepConsideringCoachAfterInitialOrder(p?: LeagueOrderState) {
  if (p) {
    if (calcCoacheshWithoutOrder(flattenCoaches(p.orderStatus), newCoachProductFilter).length) {
      return {
        currentProgram: p?.currentProgram ?? '',
        currentStep: LOSStates.NEW_COACHES,
      }
    } else return stateTransitions[LOSStates.NEW_COACHES].continue(p)
  }
  throw new LeaugeOrderSMRuntimeException('State corrupt determining if there are new coaches.')
}

/**
 * Returns next coach state when things might be missing.
 *
 * @param p - current state
 * @return next state
 */
function nextStateAfterCoaches(p?: LeagueOrderState) {
  //found a weird case where there were no coach products to order because there were no divisions,
  // but also there were missing sizes, this fixes that logic

  if (hasAddOnItemsToOrder(p)) {
    return { currentProgram: p?.currentProgram ?? '', currentStep: LOSStates.REVIEW_ADDON_ITEMS }
  }
  return { currentProgram: p?.currentProgram ?? '', currentStep: LOSStates.REVIEW_SHIPPING }
}

export const stateTransitions = {
  [LOSStates.GET_PARTICIPANT_DETAIL_PREFERENCE]: {
    proceed: (p?: LeagueOrderState) => nextStepConsideringCoachAfterInitialOrder(p),
    review: (p?: LeagueOrderState) => ({
      currentProgram: p?.currentProgram ?? '',
      currentStep: LOSStates.REVIEW_PARTICIPANT_DETAIL,
    }),
  },
  [LOSStates.REVIEW_PARTICIPANT_DETAIL]: {
    continue: (p?: LeagueOrderState) => nextStepConsideringCoachAfterInitialOrder(p),
  },
  [LOSStates.PRE_STEP]: {
    continue: (p?: LeagueOrderState) => {
      const hassizing = p?.sizing.some((s) => s.length)
      if (hassizing)
        return {
          currentProgram: p?.currentProgram ?? '',
          currentStep: LOSStates.PARTICIPANT_MISSING_SIZES,
        }
      return stateTransitions[LOSStates.PARTICIPANT_MISSING_SIZES].continue(p)
    },
  },

  [LOSStates.REVIEW_ADDON_ITEMS]: {
    continue: (p?: LeagueOrderState) => ({
      currentProgram: p?.currentProgram ?? '',
      currentStep: LOSStates.REVIEW_SHIPPING,
    }),
  },
  [LOSStates.REVIEW_SHIPPING]: {
    continue: (p?: LeagueOrderState) =>
      p?.validated
        ? {
            currentProgram: p?.currentProgram ?? '',
            currentStep: LOSStates.REVIEW_ORDER,
          }
        : { currentProgram: p?.currentProgram ?? '', currentStep: LOSStates.REVIEW_SHIPPING },
  },
  [LOSStates.REVIEW_ORDER]: {
    continue: (p?: LeagueOrderState) =>
      p?.validated
        ? {
            currentProgram: p?.currentProgram ?? '',
            currentStep: LOSStates.CONFIRM_ORDER,
          }
        : {
            currentProgram: p?.currentProgram ?? '',
            currentStep: LOSStates.REVIEW_ORDER,
          },
  },

  [LOSStates.PARTICIPANT_MISSING_SIZES]: {
    continue: (p?: LeagueOrderState) => {
      if (!isInitialOrder(p) && p) {
        if (calcPlayersWithoutOrder(flattenPlayers(p.orderStatus), newPlayerProductFilter).length) {
          return {
            currentProgram: p?.currentProgram ?? '',
            currentStep: LOSStates.NEW_PARTICIPANTS,
          }
        } else {
          return stateTransitions[LOSStates.NEW_PARTICIPANTS].continue(p)
        }
      } else {
        return {
          currentProgram: p?.currentProgram ?? '',
          currentStep: LOSStates.GET_PARTICIPANT_DETAIL_PREFERENCE,
        }
      }
    },
  },
  [LOSStates.PARTICIPANT_EXCHANGE]: {
    continue: (p?: LeagueOrderState) => {
      return nextStepConsideringCoachAfterInitialOrder(p)
    },
  },
  [LOSStates.COACH_EXCHANGE]: {
    continue: nextStateAfterCoaches,
  },
  //new participants are always add-on-league orders.
  [LOSStates.NEW_PARTICIPANTS]: {
    continue: (p?: LeagueOrderState) => ({
      currentProgram: p?.currentProgram ?? '',
      currentStep: LOSStates.PARTICIPANT_EXCHANGE,
    }),
  },
  //new participants are always add-on-league orders.
  [LOSStates.NEW_COACHES]: {
    continue: (p?: LeagueOrderState) => {
      if (p && hasCoachStuff(p) && !isInitialOrder(p)) {
        return {
          currentProgram: p?.currentProgram ?? '',
          currentStep: LOSStates.COACH_EXCHANGE,
        }
      }

      //skip exchange if no coach product or not an intial order
      return nextStateAfterCoaches(p)
    },
  },
  [LOSStates.CONFIRM_ORDER]: {},
}

export const componentState = [
  {
    step: LOSStates.PRE_STEP,
    component: 'StepIntro',
    label: 'Intro',
  },
  {
    step: LOSStates.PARTICIPANT_MISSING_SIZES,
    component: 'StepParticipantMissingSizes',
    label: 'Fix Participant Sizing',
  },
  {
    step: LOSStates.PARTICIPANT_EXCHANGE,
    component: 'StepParticipantExchange',
    label: 'Exchange Participant Sizing',
  },
  {
    step: LOSStates.COACH_EXCHANGE,
    component: 'StepCoachExchange',
    label: 'Exchange Coach Sizing',
  },
  {
    step: LOSStates.NEW_PARTICIPANTS,
    component: 'StepNewParticipants',
    label: 'New Participants since Last Order',
  },
  {
    step: LOSStates.NEW_COACHES,
    component: 'StepNewCoaches',
    label: 'Coach Order Summary',
  },
  {
    step: LOSStates.GET_PARTICIPANT_DETAIL_PREFERENCE,
    component: 'StepParticipantDetailPreference',
    label: 'Get Participant Detail Preference',
  },
  {
    step: LOSStates.REVIEW_PARTICIPANT_DETAIL,
    component: 'StepReviewParticipantDetail',
    label: 'Review Participant Detail',
  },
  {
    step: LOSStates.REVIEW_ADDON_ITEMS,
    component: 'StepSelectAddOnItems',
    label: 'Review Add-On Items',
  },
  {
    step: LOSStates.REVIEW_SHIPPING,
    component: 'StepShipping',
    label: 'Review Shipping',
  },
  {
    step: LOSStates.REVIEW_ORDER,
    component: 'StepReview',
    label: 'Review Order',
  },
  {
    step: LOSStates.CONFIRM_ORDER,
    component: 'StepConfirm',
    label: 'Confirm Order',
  },
]

export function mergeState(oldState: LeagueOrderState, newState: LeagueOrderState) {
  return cloneDeep({ ...oldState, ...newState })
}

function isXLODP(x: xLeagueOrderDetailProduct | ParticipantOrderProductInfo): x is xLeagueOrderDetailProduct {
  return (<xLeagueOrderDetailProduct>x).productID !== undefined
}

/**
 * There are couple product models, this returns the product id from two variants.
 * @param x
 */
export function getProductID(x: xLeagueOrderDetailProduct | ParticipantOrderProductInfo) {
  if (isXLODP(x)) {
    return x.productID
  } else {
    return x.id
  }
}

export const addProgramToSizing = (p: PlayerMissingSize[], program: string, headCoaches?: number[]) =>
  (p ?? []).map((x) => ({
    ...x,
    program,
    description: `${x.gender == 'M' ? 'Male' : 'Female'}`,
    isHeadCoach: (headCoaches?.indexOf(parseInt(x?.individualID)) ?? -1) >= 0,
  }))

export const stateIsLoaded = (x: LeagueOrderState) =>
  x.orderStatus?.length && x.programs?.length && x.programs[0] && x.orderStatus[0].typeProgramID

export const getHeadCoaches = (orderStatus: xLeagueOrderDetail[]) => {
  const coaches: number[] = []

  orderStatus.forEach((os) => {
    os.divisions.forEach((div) => {
      div.teams.forEach((team) => {
        team.coaches.forEach((coach) => {
          if (coach.isHeadCoach) {
            coaches.push(coach.individualID)
          }
        })
      })
    })
  })
  return coaches
}

/**
 * Asks if coaches have stuff to order.
 * @param p
 * @return boolean has coach products.
 */
function hasCoachStuff(p?: LeagueOrderState) {
  let hasCS = false
  p?.orderStatus.forEach((os) => {
    hasCS = hasCS || os.divisions.some((d) => d.teams.some((t) => t.coaches.some((c) => c.products?.length)))
  })
  return hasCS
  //  return p?.orderStatus?.length && p.orderStatus[0].coachProductsAvailable
}

/**
 * Asks if we have add-on items to order
 * @param p
 * @return boolean stuff to order
 */
function hasAddOnItemsToOrder(p?: LeagueOrderState) {
  return p?.template && p.template.products?.length
}

function isInitialOrder(p?: LeagueOrderState) {
  if (!p || !p?.orderStatus?.length) {
    throw new LeaugeOrderSMRuntimeException('Invalid state, missing an order count.')
  }

  return !p?.orderStatus[0].orderCount
}
