import { SlideType, SlideButton } from '@/components/Carousel/types/Slide'
import { ProgramKit } from '@/views/Programs/Orders/league/types/ProgramKit'
import { ProgramTypes } from '@/lib/common/ProgramTypes'

export function createDeck(
  nextHandler: (id: number) => void,
  navigateHandler: (path: string) => void,
  continueOrderHandler: () => void,
  downloadCSVHandler: () => void,
  participantKits: ProgramKit[],
  coachKits: ProgramKit[],
  upw: string,
  hasUnassignedParticipants: boolean
) {
  const participantAssignmentQuestion = {
    id: 1,
    order: 1,
    type: 'QUESTION',
    title: 'Have all participants been added using the "Participants" tab or online registration?',
    buttons: [
      {
        id: 1,
        order: 1,
        label: 'Yes',
        fnc: () =>
          nextHandler(
            hasUnassignedParticipants ? unassignedParticipantQuestion.id : participantKitQuestion.id
          ),
      } as SlideButton,
      {
        id: 2,
        order: 2,
        label: 'No',
        fnc: () => nextHandler(participantAssignmentWarning.id),
      } as SlideButton,
    ],
  } as SlideType

  const participantKitQuestion = {
    id: 2,
    order: 2,
    type: 'QUESTION',
    title: 'Is this what you want ordered for each participant?',
    body: participantKitBody(participantKits),
    buttons: [
      {
        id: 1,
        order: 1,
        label: 'Yes',
        fnc: () => nextHandler(coachAssignmentQuestion.id),
      } as SlideButton,
      {
        id: 2,
        order: 2,
        label: 'No',
        fnc: () => nextHandler(participantKitChange.id),
      } as SlideButton,
    ],
  } as SlideType

  const coachAssignmentQuestion = {
    id: 3,
    order: 3,
    type: 'QUESTION',
    title: 'Have all coaches been added and assigned to teams?',
    subtitle: 'Coach products:',
    body: coachKitBody(coachKits, upw),
    buttons: [
      {
        id: 1,
        order: 1,
        label: 'Yes',
        fnc: () => nextHandler(readyToOrder.id),
      } as SlideButton,
      {
        id: 2,
        order: 2,
        label: 'No',
        fnc: () => nextHandler(coachAssignmentWarning.id),
      } as SlideButton,
    ],
  } as SlideType

  const readyToOrder = {
    id: 5,
    order: 5,
    type: 'DONE',
    title: 'You are ready to start your order.',
    body: readyToOrderBody,
    buttons: [
      {
        id: 1,
        order: 1,
        label: 'Start Your Order',
        fnc: continueOrderHandler,
      } as SlideButton,
    ],
  } as SlideType

  const participantAssignmentWarning = {
    id: 6,
    order: 6,
    type: 'WARNING',
    title: 'Participants not entered will not appear on this order.',
    buttons: [
      {
        id: 1,
        order: 1,
        label: 'Continue to Order',
        fnc: () =>
          nextHandler(
            hasUnassignedParticipants ? unassignedParticipantQuestion.id : participantKitQuestion.id
          ),
      } as SlideButton,
      {
        id: 2,
        order: 2,
        label: 'Add Participants',
        fnc: () => navigateHandler(`/programs/league/${upw}/participants/list`),
      } as SlideButton,
    ],
  } as SlideType

  const participantKitChange = {
    id: 7,
    order: 7,
    type: 'WARNING',
    title: 'You can make changes by going to Setup > Build Player Kit',
    buttons: [
      {
        id: 1,
        order: 1,
        label: 'Continue to Order',
        fnc: () => nextHandler(coachAssignmentQuestion.id),
      } as SlideButton,
      {
        id: 2,
        order: 2,
        label: 'Go to Build Player Kit',
        fnc: () => navigateHandler(`/programs/league/${upw}/setup/products`),
      } as SlideButton,
    ],
  } as SlideType

  const coachAssignmentWarning = {
    id: 8,
    order: 8,
    type: 'WARNING',
    title: 'Coaches not assigned to teams will not appear on this order.',
    buttons: [
      {
        id: 1,
        order: 1,
        label: 'Continue to Order',
        fnc: () => nextHandler(readyToOrder.id),
      } as SlideButton,
      {
        id: 2,
        order: 2,
        label: 'Assign Coaches to Teams',
        fnc: () => navigateHandler(`/programs/league/${upw}/divisions_and_teams/coaches`),
      } as SlideButton,
    ],
  } as SlideType

  const unassignedParticipantQuestion = {
    id: 10,
    order: 10,
    type: 'QUESTION',
    warning: 'Some participants are not assigned to teams.',
    title: 'Do you want to finish adding participants to teams?',
    subtitle: ' Unassigned participants will not receive any player items on this order. ',
    buttons: [
      {
        id: 1,
        order: 1,
        label: 'Continue to Order',
        fnc: () => nextHandler(participantKitQuestion.id),
      } as SlideButton,
      {
        id: 2,
        order: 2,
        label: 'Download List of Unassigned',
        fnc: () => downloadCSVHandler(),
      } as SlideButton,
    ],
  } as SlideType

  const deck = [
    participantAssignmentQuestion,
    participantAssignmentWarning,
    participantKitQuestion,
    participantKitChange,
    coachAssignmentQuestion,
    coachAssignmentWarning,
    readyToOrder,
  ] as SlideType[]

  if (hasUnassignedParticipants) deck.push(unassignedParticipantQuestion)
  return deck
}

const readyToOrderBody = `<div>Next you will:<div>
<ul>
<li>Resolve any missing sizes</li>
<li>Confirm participant and coach apparel items and sizes</li>
<li>Add extra items to your order</li>
<li>Review costs and shipping address</li>
</ul>`

const kitBody = (programKit: ProgramKit[]) => {
  let body = ''
  let useLegend = false
  programKit.forEach((pk) => {
    const items = pk.kit
      .map((i) => {
        const marker = i.gradeGender.length ? '*' : i.purchaseType == 'OPTIONAL' ? '+' : ''
        if (!useLegend && marker != '') useLegend = true
        return `<div ${i.id == 9999 ? 'class="text-danger"' : ''}>${i.name} ${marker}</div>`
      })
      .join('')

    body += `<div class="small"><h6 class="text-underline text-center">${
      ProgramTypes[pk.program]
    }</h6>${items}</div>`
  })
  return { body: `<div class="d-flex justify-space-around">${body}</div>`, useLegend }
}

const participantKitBody = (kit: ProgramKit[]) => {
  const { body, useLegend } = kitBody(kit)
  return useLegend
    ? `${body} 
  <div class="mt-3 p-2" style="max-width:300px;">
    <div class="small text-muted">+ optional for parents to add</div>
    <div class="small text-muted">* ordered for specific grade/gender</div>
  </div>`
    : body
}

const coachKitBody = (kit: ProgramKit[], upw: string) => {
  const { body } = kitBody(kit)
  return `${body} 
    <div class="text-center">
      <a class="small mt-4 d-inline-block" href="/programs/league/${upw}/setup/products">add/change apparel</a>
    </div>`
}
