

























































import { computed, defineComponent, PropType, ref, watch, onMounted } from '@vue/composition-api'
import {
  LeagueOrderState,
  LOSStates,
  SizeUpdatedPayload,
} from '@/lib/support/store/leagueOrder/leagueOrderState'
import { ParticipantOrderProductInfo } from '@/GeneratedTypes/ListInfo/ParticipantOrderProductInfo'
import Accordion from '@/components/Accordion.vue'
import AccordionItem from '@/components/AccordionItem.vue'
import TeamSizeSelector from '@/views/Programs/Orders/league/components/TeamSizeSelector.vue'
import ProgramTab from '@/views/Programs/Orders/league/components/ProgramTab.vue'
import { stateTransitions } from '@/views/Programs/Orders/league/lib/LeagueOrderSM'
import { cloneDeep } from 'lodash'
import BackButton from '@/views/Programs/Orders/league/components/BackButton.vue'
export default defineComponent({
  components: { TeamSizeSelector, AccordionItem, ProgramTab, Accordion, BackButton },
  props: {
    state: { type: Object as PropType<LeagueOrderState>, required: true },
    itemList: { type: Array as PropType<ParticipantOrderProductInfo[]>, required: true, default: () => [] },
  },
  setup(props, ctx) {
    const currentProgram = ref('')

    function sizingUpdated(payload: SizeUpdatedPayload) {
      const newPayload = cloneDeep({ ...payload, program: currentProgram.value })
      ctx.emit('sizingUpdate', newPayload)
    }

    const orderStatus = computed(() =>
      props.state.orderStatus.find((x) => x.typeProgramID == currentProgram.value)
    )
    watch(
      () => [props.state.orderStatus, props.state.programs],
      () => {
        currentProgram.value = props.state.programs[0]
      },
      { immediate: true }
    )

    const teamPanels = ref<number[][]>([])

    /**
     * If we have an index added to the divPanels then create a team panel value to keep track
     * of expanding and collapsing.
     */
    const divPanels = ref<number[]>([])
    watch(
      () => divPanels.value,
      () => {
        divPanels.value.forEach((x) => {
          if (!teamPanels.value[x]) {
            teamPanels.value[x] = []
          }
        })
      },
      { immediate: true, flush: 'pre' }
    )

    const continueClicked = () =>
      ctx.emit('setCurrentStep', stateTransitions[LOSStates.REVIEW_PARTICIPANT_DETAIL].continue(props.state))

    const showExpand = ref(false)
    function expandAll() {
      const newDivPanel: number[] = []
      const newTeamPanel = cloneDeep(teamPanels.value)
      orderStatus.value?.divisions.forEach((o, i) => {
        newTeamPanel.splice(
          i,
          1,
          Array.from({ length: orderStatus.value?.divisions[i].teams.length ?? 0 }, (o, i) => i)
        )
        newDivPanel[i] = i
      })
      divPanels.value = newDivPanel
      ctx.root.$nextTick(() => (teamPanels.value = newTeamPanel))
      showExpand.value = false
    }
    function contractAll() {
      orderStatus.value?.divisions.forEach((o, i) => {
        teamPanels.value.splice(i, 1, [])
      })
      divPanels.value = []
      showExpand.value = true
    }

    onMounted(() => {
      expandAll()
    })

    return {
      currentProgram,
      teamPanels,
      divPanels,
      sizingUpdated,
      expandAll,
      contractAll,
      orderStatus,
      continueClicked,
      showExpand,
    }
  },
})
