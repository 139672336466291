import { computed, ComputedRef, ref } from '@vue/composition-api'
import { SizeAffectsEnum, SizeUpdatedPayload } from '@/lib/support/store/leagueOrder/leagueOrderState'
import { cloneDeep } from 'lodash'
import { PeopleWithProgram } from '@/views/Programs/Orders/league/lib/PeopleWithProgramOps'

export function useExchangeItem({
  people,
  sizeAffects,
  emit,
}: {
  people: ComputedRef<PeopleWithProgram[]>
  sizeAffects: SizeAffectsEnum
  emit: (event: string, ...args: any[]) => void
}) {
  const searchTerm = ref('')

  const peopleFound = computed(() =>
    searchTerm.value && searchTerm.value.length
      ? people.value
          .filter((p) => p.name.toLocaleLowerCase().match(searchTerm.value.toLocaleLowerCase()))
          .map((x) => ({ ...x, products: x.products.filter((p) => !(p?.replaced ?? false)) }))
          .filter((x) => x.products.length)
      : []
  )

  const exchangedSizes = computed(() => {
    return (
      people.value
        .filter((p) => p.products.some((pr) => pr.replaced))
        // replaced is a field set in state when an exchanged item overrides the local state.
        .map((x) => ({ ...x, products: x.products.filter((xp) => xp.replaced) }))
    )
  })

  function addToExchange(payload: SizeUpdatedPayload) {
    const program = peopleFound.value.find((x) => x.individualID == payload.participantID)?.program ?? ''
    emit('addToExchange', cloneDeep({ ...payload, program: program, whoIsUpdated: sizeAffects }))
  }

  function removeFromExchange(payload: SizeUpdatedPayload) {
    const program = people.value.find((x) => x.individualID == payload.participantID)?.program ?? ''

    emit('removeFromExchange', cloneDeep({ ...payload, program, whoIsUpdated: sizeAffects }))
  }

  function commitSizing(payload: SizeUpdatedPayload) {
    const program = people.value.find((x) => x.individualID == payload.participantID)?.program ?? ''
    emit('commitSizing', cloneDeep({ ...payload, program, whoIsUpdated: sizeAffects }))
  }

  return {
    exchangedSizes,
    searchTerm,
    people,
    peopleFound,
    removeFromExchange,
    addToExchange,
    commitSizing,
  }
}
