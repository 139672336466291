













import { defineComponent, PropType } from '@vue/composition-api'
import {
  xLeagueOrderDetailParticipantProduct,
  xLeagueOrderDetailTeams,
} from '@/GeneratedTypes/xOrder/xStartupOrder'
import { ParticipantOrderProductInfo } from '@/GeneratedTypes/ListInfo/ParticipantOrderProductInfo'
import MissingSizingSelector from '@/views/Programs/Orders/league/components/MissingSizingSelector.vue'
import { SizeAffectsEnum, SizeUpdatedPayload } from '@/lib/support/store/leagueOrder/leagueOrderState'
import { playerToPeopleWithProgram } from '@/views/Programs/Orders/league/lib/PeopleWithProgramOps'

export default defineComponent({
  name: 'TeamSizeSelector',
  components: { MissingSizingSelector },
  props: {
    team: { type: Object as PropType<xLeagueOrderDetailTeams> },
    itemList: { type: Array as PropType<ParticipantOrderProductInfo[]>, required: true, default: () => [] },
  },
  setup(props, ctx) {
    //...

    function sizingUpdated(payload: SizeUpdatedPayload) {
      ctx.emit('sizingUpdate', { ...payload, whoIsUpdated: SizeAffectsEnum.PLAYER } as SizeUpdatedPayload)
    }

    const players =
      props.team?.players.map((p) => playerToPeopleWithProgram(p, 'placeholder-program', '')) ?? []

    const filter = (p: xLeagueOrderDetailParticipantProduct) =>
      p.typeSizeID !== 'NONE' || p.typeProductOptionID == 'INCLUDED'

    return { sizingUpdated, players, filter }
  },
})
