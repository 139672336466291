
















































import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api'
import {
  LeagueOrderState,
  LOSStates,
  SizeAffectsEnum,
} from '@/lib/support/store/leagueOrder/leagueOrderState'
import { ParticipantOrderProductInfo } from '@/GeneratedTypes/ListInfo/ParticipantOrderProductInfo'
import MissingSizingSelector from '@/views/Programs/Orders/league/components/MissingSizingSelector.vue'
import { stateTransitions } from '@/views/Programs/Orders/league/lib/LeagueOrderSM'
import VeeValidateForm from '@/elements/VeeValidateForm.vue'
import SearchInput from '@/elements/SearchInput.vue'
import PeopleFoundGrid from '@/views/Programs/Orders/league/components/PeopleFoundGrid.vue'
import { useExchangeItem } from '@/views/Programs/Orders/league/lib/useExchangeItem'
import BackButton from '@/views/Programs/Orders/league/components/BackButton.vue'
import {
  exchangePlayerProductFilter,
  flattenPlayers,
  passOptionalButSizedProductFilter,
  PeopleWithProgram,
} from '@/views/Programs/Orders/league/lib/PeopleWithProgramOps'

export default defineComponent({
  name: 'StepParticipantExchange',
  components: { PeopleFoundGrid, BackButton, SearchInput, MissingSizingSelector, VeeValidateForm },
  props: {
    state: { type: Object as PropType<LeagueOrderState>, required: true },
    itemList: { type: Array as PropType<ParticipantOrderProductInfo[]>, required: true, default: () => [] },
  },

  methods: { passOptionalButSizedProductFilter },

  setup(props, ctx) {
    const people = ref<PeopleWithProgram[]>([])
    const useExchange = useExchangeItem({
      people: computed(() =>
        people.value
          .filter(exchangePlayerProductFilter)
          .map((x) => ({
            ...x,
            products: x.products.filter((p) => p.upwardOrderID),
          }))
          .filter((x) => x.products.length)
      ),
      sizeAffects: SizeAffectsEnum.PLAYER,
      emit: ctx.emit,
    })

    watch(
      () => props.state,
      () => (people.value = flattenPlayers(props.state.orderStatus)),
      { deep: true, immediate: true }
    )

    const continueClicked = () =>
      ctx.emit('setCurrentStep', stateTransitions[LOSStates.PARTICIPANT_EXCHANGE].continue(props.state))

    return {
      continueClicked,
      ...useExchange,
    }
  },
})
