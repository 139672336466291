



















































import { defineComponent, PropType, ref, watch, onMounted } from '@vue/composition-api'
import { cloneDeep } from 'lodash'
import { ParticipantOrderProductInfo } from '@/GeneratedTypes/ListInfo/ParticipantOrderProductInfo'
import SizePerson from '@/views/Programs/Orders/league/components/SizePerson.vue'
import VeeValidateForm from '@/elements/VeeValidateForm.vue'
import SizeCoach from '@/views/Programs/Orders/league/components/SizeCoach.vue'
import { xLeagueOrderDetailParticipantProduct } from '@/GeneratedTypes/xOrder/xStartupOrder'
import { PeopleWithProgram } from '@/views/Programs/Orders/league/lib/PeopleWithProgramOps'
import store from '@/store'

export default defineComponent({
  name: 'MissingSizingSelector',
  components: {
    VeeValidateForm,
    SizePerson,
    SizeCoach,
  },
  props: {
    //*** coach strategy is set one size from one of several products, players set size on all required products
    useCoachProductSelectionStrategy: { type: Boolean, required: false, default: false },
    //*** show grade column
    showGrade: { type: Boolean, required: false, default: true },
    //*** show head coach column
    showHeadCoachColumn: { type: Boolean, required: false, default: false },
    //*** include DNO
    hasDNO: { type: Boolean, required: false, default: true },
    //*** value of the item.
    value: { type: Array as PropType<PeopleWithProgram[]>, required: true, default: () => [] },
    //*** flag these participants as not complete
    needsAttentionList: { type: Array as PropType<number[]>, required: false, default: () => [] },
    itemList: { type: Array as PropType<ParticipantOrderProductInfo[]>, required: true, default: () => [] },
    filter: {
      type: Function as PropType<(x: xLeagueOrderDetailParticipantProduct) => boolean>,
      required: false,
      default: () => () => true,
    },
    columnOption: { type: String, required: false, default: '' },
  },
  setup(props, ctx) {
    const internalValue = ref<PeopleWithProgram[]>([])

    onMounted(async () => {
      await store.dispatch.sizeTypes.fetchAll(false) //get these cached up
    })

    watch(
      () => props.value,
      () =>
        (internalValue.value = cloneDeep(
          props.value
            .map((p) => ({ ...p, products: p.products.filter(props.filter) }))
            .filter((p) => p.products?.length)
        )),
      { immediate: true }
    )
    function sizingUpdated(payload: unknown) {
      ctx.emit('sizingUpdate', payload)
    }
    function sizingClicked(payload: unknown) {
      ctx.emit('sizingClick', payload)
    }

    function isEven(row: number) {
      return row % 2 == 0
    }

    return {
      internalValue,
      sizingUpdated,
      sizingClicked,
      isEven,
    }
  },
})
