/**
 * originally designed to mixin to components that might have back button logic the backbutton has
 * been moved to its own component.
 */
export function useBack() {
  const backClicked = () => {
    window.history.back()
  }

  return { backClicked }
}
