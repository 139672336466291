



























import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api'

export default defineComponent({
  props: {
    value: { type: String, required: false, default: '' },
    programs: { type: Array as PropType<string[]>, required: true, default: () => [] },
  },
  setup(props, ctx) {
    const internalIndex = ref(0)
    const internalValue = computed(() => props.programs[internalIndex.value])
    watch(
      () => props.value,
      () => {
        const i = props.programs.indexOf(props.value)
        if (i >= 0) {
          internalIndex.value = i
        }
      },
      { immediate: true }
    )
    function select(i: number) {
      internalIndex.value = i
      ctx.emit('input', internalValue.value)
    }
    return { select, internalIndex }
  },
})
