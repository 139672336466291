






















































import { computed, defineComponent, PropType, ref, onMounted } from '@vue/composition-api'
import Loading from '@/elements/Loading.vue'
import { LeagueOrderState, LOSStates } from '@/lib/support/store/leagueOrder/leagueOrderState'
import { stateIsLoaded, stateTransitions } from '@/views/Programs/Orders/league/lib/LeagueOrderSM'
import { LeagueAbstraction } from '@/models/LeagueAbstraction/LeagueAbstraction'
import { LeagueTypeEnum } from '@/models/LeagueAbstraction/enums/LeagueTypeEnum'
import Carousel from '@/components/Carousel/Carousel.vue'
import { createDeck } from '@/views/Programs/Orders/league/lib/leagueOrderDeck'
import store from '@/store'
import { ParticipantOrderProductInfo } from '@/GeneratedTypes/ListInfo/ParticipantOrderProductInfo'
import { buildKits } from '@/views/Programs/Orders/league/lib/buildKit'
import { cloneDeep } from 'lodash'
import { SlideType } from '@/components/Carousel/types/Slide'
import divisionsClient from '@/clients/divisionsClient'
import participantsClient from '@/clients/participantsClient'
import { UnassignedParticipants } from '@/views/Programs/Orders/league/types/UnassignedParticipant'
import { downloadCSV } from '@/lib/common/csv/downloadCSV'
import { GridColumn } from '@/models/Grid/GridColumn'
import { LeaguePlayerInfo } from '@/GeneratedTypes/ListInfo/LeaguePlayerInfo'
import { LeagueDivisionInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionInfo'

export default defineComponent({
  components: { Loading, Carousel },
  props: {
    league: { type: Object as PropType<LeagueAbstraction>, required: true },
    state: { type: Object as PropType<LeagueOrderState>, required: true },
    itemList: { type: Array as PropType<ParticipantOrderProductInfo[]>, required: true, default: () => [] },
  },
  setup(props, ctx) {
    const currentLeague = computed(() => store.getters.leagues.currentItem)
    const current = ref(0)
    const stillLoading = computed(() => !stateIsLoaded(props.state) && loading.value)
    const deck = ref<SlideType[]>([])
    const loading = ref(false)
    const unassigned = ref<UnassignedParticipants[] | null>(null)
    function continueClicked() {
      ctx.emit('setCurrentStep', stateTransitions[LOSStates.PRE_STEP].continue(props.state))
    }
    const setSlide = (slideId: number) => (current.value = slideId)
    const isLeagueOrder = computed(() => !stillLoading.value && props.league.type === LeagueTypeEnum.LEAGUE)
    const isCampOrder = computed(() => !stillLoading.value && props.league.type === LeagueTypeEnum.CAMP)

    const isFirstLeagueOrder = computed(() => {
      return isLeagueOrder.value && props.state.orderStatus[0].orderCount == 0
    })

    function isAdditionalLeagueOrder() {
      return isLeagueOrder.value && props.state.orderStatus[0].orderCount > 0
    }

    function isFirstCampOrder() {
      return isCampOrder.value && props.state.orderStatus[0].orderCount == 0
    }

    function isAdditionalCampOrder() {
      return isCampOrder.value && props.state.orderStatus[0].orderCount > 0
    }

    // for first league orders, prep slides
    const next = (slideId: number) => (current.value = slideId)
    const navigate = (path: string) => ctx.root.$router.push(path)

    const { participantKits, coachKits } = buildKits(
      cloneDeep(currentLeague.value.productOfferingConfig?.details) ?? [],
      cloneDeep(props.itemList),
      cloneDeep(props.state.programs)
    )

    async function getUnassigned(divisions: LeagueDivisionInfo[] | null) {
      if (divisions) {
        const calls: Promise<unknown>[] = []
        const all = [] as LeaguePlayerInfo[]
        divisions.forEach((d) => {
          calls.push(
            participantsClient.retrieveUnassigned(d.upwardLeagueID ?? '', d.typeProgramID ?? '', d.divisionID)
          )
        })
        const r = await Promise.allSettled(calls)
        const fulfilled = r.filter(({ status }) => status == 'fulfilled')

        fulfilled.forEach((x: any) => {
          if (x.status == 'fulfilled') {
            all.push(...(x.value as LeaguePlayerInfo[]))
          }
        })
        return all
      }
      return [] as LeaguePlayerInfo[]
    }

    const participantsUnassigned = async () => {
      loading.value = true
      const divisions = await divisionsClient.retrieveAll(props.league.upwardLeagueID ?? '')
      const all = await getUnassigned(divisions)
      unassigned.value = all.map((a) => {
        return {
          firstName: a.firstName,
          lastName: a.lastName,
          gender: a.gender,
          grade: a.typeGradeID,
          program: a.typeProgramID,
        } as UnassignedParticipants
      })
      buildDeck()
      loading.value = false
    }
    function buildDeck() {
      deck.value = createDeck(
        next,
        navigate,
        continueClicked,
        download,
        participantKits,
        coachKits,
        ctx.root.$route.params.id,
        unassigned.value ? unassigned.value.length > 0 : false
      )
      current.value = deck.value[0].id
    }
    onMounted(async () => {
      if (isFirstLeagueOrder.value) {
        await participantsUnassigned()
      }
    })

    const downloadColumns: GridColumn[] = [
      { field: 'program', headerText: 'Program' },
      { field: 'firstName', headerText: 'First Name' },
      { field: 'lastName', headerText: 'Last Name' },
      { field: 'gender', headerText: 'Gender' },
      { field: 'grade', headerText: 'Grade' },
    ]

    const downloadColumnsByAge: GridColumn[] = [
      { field: 'program', headerText: 'Program' },
      { field: 'firstName', headerText: 'First Name' },
      { field: 'lastName', headerText: 'Last Name' },
      { field: 'gender', headerText: 'Gender' },
      { field: 'ageByCutoff', headerText: 'Age' },
    ]
    function download() {
      if (unassigned.value) {
        const isByAge = props.league.ageCutoffDate != null
        downloadCSV(
          isByAge ? downloadColumnsByAge : downloadColumns,
          isByAge
            ? unassigned.value.map((x) => {
                return {
                  ...x,
                  ageByCutoff: store.getters.gradeTypes.byUpwardTypeId(x.grade)?.ageByCutoff ?? '',
                }
              })
            : unassigned.value,
          'unassigned_participants.csv'
        )
      }
    }
    const questionCount = computed(() => {
      const questions = deck.value.filter((s) => s.type == 'QUESTION')
      return questions ? questions.length : 0
    })

    return {
      stillLoading,
      continueClicked,
      LeagueTypeEnum,
      deck: computed(() => deck.value),
      current: computed(() => current.value),
      setSlide,
      isFirstLeagueOrder: computed(() => isFirstLeagueOrder.value),
      isAdditionalLeagueOrder: computed(isAdditionalLeagueOrder),
      isFirstCampOrder: computed(isFirstCampOrder),
      isAdditionalCampOrder: computed(isAdditionalCampOrder),
      questionCount,
    }
  },
})
