




































































































import { defineComponent, PropType, ref } from '@vue/composition-api'
import SectionHeader from '@/components/SectionHeader.vue'
import ShippingAddressSection from '@/components/ShippingAddressSection.vue'
import OrderListSection from '@/components/OrderHistory/OrderListSection.vue'
import InputLabel from '@/elements/InputLabel.vue'
import { computed } from '@vue/composition-api'
import { useNoProductSelector } from '@/views/Programs/Orders/compositions/useProductSelector'
import { LeagueOrderState } from '@/lib/support/store/leagueOrder/leagueOrderState'
import date from '@/filters/date'
import OrderRollup from '@/views/Programs/Orders/league/components/OrderRollup.vue'
import Loading from '@/elements/Loading.vue'
import ShippingAddressDisplay from '@/components/ShippingAddressDisplay.vue'
import { SectionNameConversion } from '@/views/Programs/Orders/vues/history/ext/SectionNames'
import { useOrderRollup } from '@/views/Programs/Orders/compositions/userOrderRollup'
import { ParticipantOrderProductInfo } from '@/GeneratedTypes/ListInfo/ParticipantOrderProductInfo'

export default defineComponent({
  components: {
    Loading,
    OrderRollup,
    SectionHeader,
    ShippingAddressSection,
    OrderListSection,
    InputLabel,
    ShippingAddressDisplay,
  },
  filters: {
    date,
  },
  props: {
    state: { type: Object as PropType<LeagueOrderState>, required: true },
    itemList: { type: Array as PropType<ParticipantOrderProductInfo[]>, required: true, default: () => [] },
  },

  setup(props) {
    const showOrderDetails = ref(false)

    const lastCompletedOrder = computed(() => props.state.template)
    const orderRollup = useOrderRollup()
    orderRollup.getRollup(props.state.template.upwardLeagueID ?? '', props.state.template.upwardOrderID ?? '')

    function print() {
      window.print()
    }

    return {
      showOrderDetails,
      lastCompletedOrder,
      selectorMethod: useNoProductSelector({
        currentOrderProductsRef: computed(() => props.state.template.products),
      }),
      shippingMethods: computed(() => props.state?.template.shippingInfo?.shipMethods),
      orderType: computed(() => SectionNameConversion[props.state.template.upwardOrderType ?? '']),
      rollups: computed(() => orderRollup.rollup.value),
      print,
    }
  },
})
