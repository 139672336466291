import {
  xLeagueOrderDetail,
  xLeagueOrderDetailCoach,
  xLeagueOrderDetailParticipant,
} from '@/GeneratedTypes/xOrder/xStartupOrder'
import lodash, { cloneDeep, groupBy } from 'lodash'
import { PlayerMissingSize, PlayerMissingSizeProduct } from '@/models/Order/PlayerMissingSize'
import { isSizeUnselected } from '@/models/Order/OrderProduct'

export interface PeopleWithProgram extends xLeagueOrderDetailParticipant {
  program: string
  isHeadCoach: boolean
  description: string
}

/***
 * Return a flat player array out of a bunch of divisions and teams
 * @param orderStatus
 */
export function flattenPlayers(orderStatus: xLeagueOrderDetail[]) {
  const p: PeopleWithProgram[] = []
  orderStatus.forEach((program) =>
    program.divisions.forEach((div) =>
      div.teams.forEach((team) =>
        team.players.forEach((player) => p.push(playerToPeopleWithProgram(player, program.typeProgramID, '')))
      )
    )
  )
  return lodash.uniqBy(p, (x) => x.individualID)
}

/**
 * Produces a list of coaches from the division/team/coach structure.
 * @param orderStatus
 */
export function flattenCoaches(orderStatus: xLeagueOrderDetail[]) {
  const p: PeopleWithProgram[] = []
  orderStatus.forEach((program) =>
    program.divisions.forEach((div) =>
      div.teams.forEach((team) =>
        // for everything we are doing treating a coach like a participant here is oK
        team.coaches.forEach((coach) =>
          p.push(
            coachToPeopleWithProgram(
              coach,
              program.typeProgramID,
              `${coach.isHeadCoach ? 'Head Coach' : 'Coach'} of ${team.name}`
            )
          )
        )
      )
    )
  )
  return lodash.uniqBy(p, (x) => x.individualID)
}

//*** pass required and not required but sized.
export const passOptionalButSizedProductFilter = (x: PlayerMissingSizeProduct) =>
  (!isSizeUnselected(x.typeSizeID) && !x.isRequired) || x.isRequired
// pass all products that are required and their sizes aren't set.
export const passRequiredProductsWithSizeUnsetProductFilter = (x: PlayerMissingSizeProduct) =>
  (x.isRequired && isSizeUnselected(x.typeSizeID)) || x.doNotOrder || x.skipNextOrder

// list of players that have sizes unset or no DNO.
export const validatePlayersMissingSizes = (x: PlayerMissingSize) =>
  x.products
    .filter((p) => p.isRequired)
    .some((y) => isSizeUnselected(y.typeSizeID) && !y.doNotOrder && !y.skipNextOrder)

export const passNonDNOProductFilter = (x: PlayerMissingSizeProduct) => !x.doNotOrder
/**
 * will return the players that are eligible for exchange (e.g. need a product that is ordered, req
 * @param x
 */
export const exchangePlayerProductFilter = (x: PeopleWithProgram) => x.products.some((p) => p.upwardOrderID)

export const newPlayerProductFilter = (x: PeopleWithProgram) =>
  x.products.some((p) => {
    if (!p.upwardOrderID && p.isRequired) {
      return true
    }
    if (!p.upwardOrderID && !p.isRequired && p.typeSizeID != 'NONE') {
      return true
    }
    return false
  })

export const newPlayerValidatedFilter = (x: PeopleWithProgram) =>
  x.products
    .filter((x) => x.isRequired)
    .every((p) => !isSizeUnselected(p.typeSizeID) || p.doNotOrder || p.skipNextOrder)

export const calcPlayersWithoutOrder = (p: PeopleWithProgram[], filter: (x: PeopleWithProgram) => boolean) =>
  p
    .filter(filter)
    .map((x) => ({ ...x, products: x.products.filter((p) => !p.upwardOrderID) }))
    .filter((x) => x.products.length)

export const calcCoacheshWithoutOrder = (
  p: PeopleWithProgram[],
  filter: (x: PeopleWithProgram) => boolean
) => {
  return p
    .filter(filter)
    .map((x) => ({
      ...x,
      products: Object.entries(groupBy(x.products, 'parentProductID'))
        .filter(
          (f) =>
            f[0] == '0'
              ? f[1].some((pr) => !pr.upwardOrderID) //any standalone products that haven't been ordered
              : !f[1].some((pr) => pr.upwardOrderID) //none of the products with the same parent have been ordered
        )
        .flatMap((m) => m[1]),
    }))
    .filter((x) => x.products.length)
}
export const newCoachProductFilter = (x: PeopleWithProgram) => {
  /* This filtering was introduced because female coaches can chose between having the fitted cut
   or the straight cut for the polo shirt, but, from the data returned by the back-end, there is no
   way to know that the coach should only get one or the other, and never both.
   To plug this whole, this filtering makes sure that if a product has been ordered for a coach,
   then that coach is not displayed again for add on orders. If, in the future, more than one
   product can be ordered for a coach, then this will have to let it through
   */
  /* TNT - 11/8/22 - the future mentioned above is now.  
  const hasOrderedProducts = x.products.some((p) => p.upwardOrderID)
  return hasOrderedProducts
    ? !hasOrderedProducts
    : x.products.some((p) => !p.upwardOrderID || isSizeUnselected(p.typeSizeID) || p.doNotOrder)
  */
  const groupedProducts = groupBy(x.products, 'parentProductID')
  return Object.entries(groupedProducts).some(
    (x) =>
      x[0] == '0'
        ? x[1].some((p) => !p.upwardOrderID) //any standalone products that haven't been ordered
        : !x[1].some((p) => p.upwardOrderID) //none of the products with the same parent have been ordered
  )
}

/*export const coachValidatedProductFilter = (x: PeopleWithProgram) =>
  x.products.some((p) => !isSizeUnselected(p.typeSizeID) || p.doNotOrder)
*/

export const coachValidatedProductFilter = (x: PeopleWithProgram) => {
  const groupedProducts = groupBy(x.products, 'parentProductID')
  return !Object.entries(groupedProducts).some(
    (x) =>
      x[0] == '0'
        ? x[1].some((p) => isSizeUnselected(p.typeSizeID) && !p.doNotOrder && !p.skipNextOrder) //standalone products without sizes and have not been marked DNO
        : !x[1].some((p) => !isSizeUnselected(p.typeSizeID) || p.doNotOrder || p.skipNextOrder) //none of the products with the same parent have been sized or marked as DNO
  )
}

/**
 * Converts a coach to a people with a program.
 * @param p - person to conver to a people with program.
 * @param program - program name
 * @param description - subheading if you want one
 */
export function playerToPeopleWithProgram(
  p: xLeagueOrderDetailParticipant,
  program: string,
  description: string
) {
  return cloneDeep({ ...p, program, description, isHeadCoach: false })
}

/**
 * Converts a coach  to people with a program.
 * @param p - person to convert to a people with program.
 * @param program - program name
 * @param description - subheading if you want one
 */
export function coachToPeopleWithProgram(
  p: xLeagueOrderDetailCoach,
  program: string,
  description: string
): PeopleWithProgram {
  return cloneDeep({
    name: p.name,
    ProductCost: '',
    products: p.products.map((x) => ({
      productID: x.productID,
      quantity: x.quantity,
      doNotOrder: x.doNotOrder,
      skipNextOrder: x.skipNextOrder,
      typeSizeID: x.typeSizeID,
      replaced: x.replaced ?? false,
      typeColorID: x.typeColorID,
      cmsProductName: x.cmsProductName,
      cmsProductDescription: x.cmsProductDescription,
      cmsProductImageUrls: x.cmsProductImageUrls,
      lockedInCost: x.lockedInCost,
      categoryID: x.categoryID,
      costoffset: 0,
      isRequired: false,
      typeProductOptionID: '',
      upwardOrderID: x.upwardOrderID ?? '',
      jerseyNumber: '',
      parentProductID: x.parentProductID,
    })),
    individualID: p.individualID,
    program,
    description,
    isHeadCoach: p.isHeadCoach,
  })
}
