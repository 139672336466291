




































import { computed, defineComponent, PropType } from '@vue/composition-api'
import { LeagueOrderState } from '@/lib/support/store/leagueOrder/leagueOrderState'
import currency from '@/filters/currency'

export default defineComponent({
  name: 'OrderPriceBreakdown',
  components: {},
  methods: { currency },
  props: {
    state: { type: Object as PropType<LeagueOrderState>, required: true },
  },

  setup(props) {
    return {
      currentOrder: computed(() => props.state.template),
      discount: computed(() => props.state.template?.longevityDiscountAmount ?? 0),
    }
  },
})
