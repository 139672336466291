






































import { computed, defineComponent, onMounted, PropType, ref } from '@vue/composition-api'
import { stateTransitions } from '@/views/Programs/Orders/league/lib/LeagueOrderSM'
import { LeagueOrderState, LOSStates } from '@/lib/support/store/leagueOrder/leagueOrderState'
import ProductSelect from '@/components/Orders/ProductSelect.vue'
import ProductQuantitySelector from '@/views/Programs/Orders/vues/components/ProductQuantitySelector.vue'
import ordersClient from '@/clients/ordersClient'
import { UpwardSizeTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardSizeTypeID'
import AddOnCart from '@/components/Orders/AddOnCart.vue'
import BackButton from '@/views/Programs/Orders/league/components/BackButton.vue'
import { PendingOrderAdditionalProductInfo } from '@/GeneratedTypes/ListInfo/PendingOrderAdditionalProductInfo'
import store from '@/store'
import lodash from 'lodash'

export default defineComponent({
  components: {
    ProductSelect,
    ProductQuantitySelector,
    AddOnCart,
    BackButton,
  },
  props: {
    state: { type: Object as PropType<LeagueOrderState>, required: true },
  },
  setup(props, ctx) {
    const currentOrderProducts = computed(() =>
      lodash.uniqBy(props.state.template.products, (x) => x.duplicateProductMatchingKey)
    )
    const sizes = ref<UpwardSizeTypeID[]>([])

    onMounted(async () => {
      sizes.value = await ordersClient.getSizes()
    })

    async function add(x: PendingOrderAdditionalProductInfo) {
      await store.dispatch.leagueOrders.addOrUpdate_AddOnProduct({
        upwId: upwId.value,
        product: x,
      })
    }

    async function remove(x: PendingOrderAdditionalProductInfo) {
      await store.dispatch.leagueOrders.remove_AddOnProduct({
        upwId: upwId.value,
        product: x,
      })
    }

    const upwId = computed(() => store.getters.leagueAbstraction.currentItem.upwardLeagueID ?? '')

    return {
      currentOrderProducts,
      sizes,
      add,
      remove,
      continueClicked: () =>
        ctx.emit('setCurrentStep', stateTransitions[LOSStates.REVIEW_ADDON_ITEMS].continue(props.state)),
    }
  },
})
