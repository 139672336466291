














































import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api'
import {
  LeagueOrderState,
  LOSStates,
  SizeAffectsEnum,
} from '@/lib/support/store/leagueOrder/leagueOrderState'
import { ParticipantOrderProductInfo } from '@/GeneratedTypes/ListInfo/ParticipantOrderProductInfo'
import MissingSizingSelector from '@/views/Programs/Orders/league/components/MissingSizingSelector.vue'
import { stateTransitions } from '@/views/Programs/Orders/league/lib/LeagueOrderSM'
import SearchInput from '@/elements/SearchInput.vue'
import PeopleFoundGrid from '@/views/Programs/Orders/league/components/PeopleFoundGrid.vue'
import { useExchangeItem } from '@/views/Programs/Orders/league/lib/useExchangeItem'
import BackButton from '@/views/Programs/Orders/league/components/BackButton.vue'

import {
  exchangePlayerProductFilter,
  flattenCoaches,
  PeopleWithProgram,
} from '@/views/Programs/Orders/league/lib/PeopleWithProgramOps'

export default defineComponent({
  name: 'StepCoachExchange',
  components: { BackButton, PeopleFoundGrid, SearchInput, MissingSizingSelector },
  props: {
    state: { type: Object as PropType<LeagueOrderState>, required: true },
    itemList: { type: Array as PropType<ParticipantOrderProductInfo[]>, required: true, default: () => [] },
  },

  setup(props, ctx) {
    const people = ref<PeopleWithProgram[]>([])
    watch(
      () => props.state,
      () => {
        people.value = flattenCoaches(props.state.orderStatus)
      },
      { deep: true, immediate: true }
    )
    const continueClicked = () =>
      ctx.emit('setCurrentStep', stateTransitions[LOSStates.COACH_EXCHANGE].continue(props.state))

    return {
      continueClicked,

      ...useExchangeItem({
        people: computed(() =>
          people.value
            .filter(exchangePlayerProductFilter)
            .map((x) => ({
              ...x,
              products: x.products.filter((p) => p.upwardOrderID),
            }))
            .filter((x) => x.products.length)
        ),
        sizeAffects: SizeAffectsEnum.COACH,
        emit: ctx.emit,
      }),
    }
  },
})
